import React from 'react'

export default function Choose() {
    return (
        <>
            <div className="about-sec-3 overflow-hidden space-top position-relative z-index-common" data-bg-src="assets/img/bg/about-bg2.png">
                <div className="gr-bg1 overlay" />
                <div className="container">
                    <div className="about-wrap3">
                        <div className="row gy-40">
                            <div className="col-xl-6">
                                <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title"># Why Choose Us</span>
                                    <h2 className="sec-title">Our Values & Inspirations</h2>
                                    <div className="checklist">
                                        <ul>
                                            <li><i className="fas fa-circle-check" /> Gaming-friendly platform with 24/7 support & global availability.
                                            </li>
                                            <li><i className="fas fa-circle-check" /> Onboarding millions of users to the blockchain & cryptocurrency industry.
                                            </li>
                                            <li><i className="fas fa-circle-check" /> Worldwide community of gamers who support growth & blockchain innovations.
                                            </li>
                                            <li><i className="fas fa-circle-check" />Upscaling the gaming industry while embracing decentralization.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="img-box3">
                                    <div className="img1"><img src="assets/img-new/our_values.png" alt="About" /></div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="img-box3">
                                    <div className="img1"><img src="assets/img/inspiration.png" alt="About" /></div>
                                </div>
                                <div className="about-content custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <p>PlayUp is the global platform for decentralized gaming that puts blockchain & cryptocurrency forward. Our platform offers gamers a seamless experience in playing online games while being rewarded with cryptocurrency. </p>
                                    <p className="mb-0">By leveraging the functionality of blockchain & smart contracts, PlayUp allows gamers to access the wide decentralized ecosystem where crypto assets are the value of exchange & native currency of transactions. Join the gaming evolution with us & let’s lead PlayUp to the top!
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="about-tag">
                            <div className="about-experience-tag"><span className="circle-title-anime">24 YEARS EXPERIENCE OF
                                GAMING</span></div>
                            <div className="about-tag-icon"><img src="assets/img/logo-icon.svg" alt="img" /></div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>

    )
}
