import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WhayUs() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className="feature-game-area-1 bg-black">
                <div className="container">
                    <div className="slider-area feature-game-slider1">
                        <div className="swiper th-slider" id="featureGameSlider1" data-slider-options="{&quot;breakpoints&quot;:{&quot;0&quot;:{&quot;slidesPerView&quot;:1},&quot;576&quot;:{&quot;slidesPerView&quot;:&quot;1&quot;},&quot;768&quot;:{&quot;slidesPerView&quot;:&quot;2&quot;},&quot;992&quot;:{&quot;slidesPerView&quot;:&quot;3&quot;},&quot;1200&quot;:{&quot;slidesPerView&quot;:&quot;3&quot;}}}">
                            <Slider {...settings}>
                                <div className="">
                                    <div className="feature-game-card mx-4">
                                        <div className="feature-game-card-img">
                                            <div className="feature-card-img-shape icon-masking">
                                                <img src="assets/img/casino_game.png" alt="img" />
                                            </div>
                                            <img src="assets/img/casino_game.png" alt="game image" />
                                            <a className="th-btn">PLAY NOW <i className="fa-solid fa-arrow-right ms-2" /></a>
                                        </div>
                                        <div className="feature-game-card-details">
                                            <h3 className="box-title"><a>Casino</a></h3>
                                            <p className="text">Make live bets & try your luck in different casino games.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="feature-game-card mx-4">
                                        <div className="feature-game-card-img">

                                            <div className="feature-card-img-shape icon-masking">
                                                <img src="assets/img/fantasy.png" alt="img" />
                                            </div>
                                            <img src="assets/img/fantasy.png" alt="game image" />
                                            <a className="th-btn">PLAY NOW <i className="fa-solid fa-arrow-right ms-2" /></a>
                                        </div>
                                        <div className="feature-game-card-details">
                                            <h3 className="box-title"><a>
                                                Fantasy
                                            </a>
                                            </h3>
                                            <p className="text">Bet on sports tournaments in football, cricket, baseball or other games</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="feature-game-card mx-4">
                                        <div className="feature-game-card-img">
                                            <div className="feature-card-img-shape icon-masking">
                                                <img src="assets/img/arcade.png" alt="img" />
                                            </div>
                                            <img src="assets/img/arcade.png" alt="game image" />
                                            <a className="th-btn">PLAY NOW <i className="fa-solid fa-arrow-right ms-2" /></a>
                                        </div>
                                        <div className="feature-game-card-details">
                                            <h3 className="box-title">
                                               <a>Arcade</a> 
                                            </h3>
                                            <p className="text">Play arcade games while competing against others & win rewards
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
