import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Hero() {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      };
    
    return (
        <>
            <div className="th-hero-wrapper hero-3" id="hero">
                <video muted
                    autoPlay
                    loop id="myVideo" className='video'><source src="./assets/img/hero_banner.mp4" type="video/mp4" /></video>

                <div className="th-hero-bg" />
                <div className="container">
                    <div className="hero-style3"><span className="sub-title custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.1s"> {t('hero.line')}</span>
                        <h1 className="hero-title custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.2s">{t('hero.decentralized')}</h1>
                        <h1 className="hero-title style2 text-theme custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.3s">{t('hero.ghub')}</h1>
                        <div className="btn-group custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.5s"><Link className="th-btn">{t('playnow')} <i className="fa-solid fa-arrow-right ms-2" /></Link>
                        </div>
                        {/* <button onClick={() => changeLanguage('en')}>English</button>
                    <button onClick={() => changeLanguage('es')}>Spanish</button>
                    <button onClick={() => changeLanguage('fr')}>French</button> */}
                    </div>
                    

                </div>
            </div>

        </>
    )
}
