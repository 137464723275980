import React from 'react'
import Hero from './Hero'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import WhayUs from './WhayUs'
import About from './About'
import BigMatch from './BigMatch'
import Features from './Features'
import Customer from './Customer'
import Choose from './Choose'
import Trancparency from './Trancparency'

export default function Home() {
    return (
        <>
            <Header />
            <Hero />
            <WhayUs/>
            <About />
            <BigMatch />
            <Features />
            <Choose />
            <br /><br />
            <Trancparency />
            {/* <Customer/> */}
            <Footer />
        </>
    )
}
